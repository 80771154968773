/**
 * Capitalizes the first letter of each word in the given string.
 * @param str The input string to be capitalized.
 * @returns The capitalized string.
 */
export function capitalizeWords(str: string): string {
	return str.replace(/\b\w/g, char => char.toUpperCase())
}

// Substitute a symbol in a string to another symbol
export function substituteSymbol(
	originString: string,
	originSymbol: string,
	replacement: string,
): string {
	const regex = new RegExp(originSymbol, 'g')
	return originString.replace(regex, replacement)
}
